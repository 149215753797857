div.buscador_container {
    display: flex;
    justify-content: center;
    height: 120vh;
    background-image: url('../../img/vectorpaint.svg');
    background-repeat: no-repeat;
    background-size: 100vw 100%;
}

div.buscador_container div.contenido {
   margin-top: 5%;
    text-align: center;
}

div.buscador_container div.contenido img.logo_dominicana {
    width: 100%;
}

div.buscador_container h2 {
    font-size: 30px;
    font-weight: 500;
    color: #1F3C73;
}

div.buscador_container a {
    font-size: 20px;
    font-weight: 300;
    color: #1F3C73;
    text-decoration: none;
}


div.buscador_container input.input_buscador {
    font-size: 20px;
    color: #000;
    border-width: 0.5px 0.5px 0.5px 0.5px;
    margin: 4%;
    padding: 3%;
    width: 90%;
}

div.buscador_container div.contenido ul li {
   list-style: none;
    font-size: 20px;
    color: #1F3C73;
}

/*Tabla*/

table {
    width: 100%;
    border-collapse: collapse;
}

th {
    background-color: #1F3C73;
    color: #fff;
    padding: 3%;
}

 td {
    color: #1F3C73;
    padding: 3%;
    border: 1px solid #1F3C73;
    border-collapse: collapse;
  }
  



/*Responsive*/

@media only screen and (max-width: 992px) {
    div.buscador_container {
        height: 100vh;
        background-position-y: 306vh;
        background-position-x: 160vh;
    }
    div.buscador_container div.contenido {
        width: 45%;
    }
}

@media only screen and (max-width: 768px) {
    div.buscador_container {
        height: 100vh;
        background-position-y: 137vh;
        background-position-x: 178vw;
    }
    div.buscador_container div.contenido {
        width: 45%;
        margin-top: 15%;
    }
    div.buscador_container h1, div.buscador_container h3 {
        color: #fff;
    }
}

@media only screen and (max-width: 600px) {

    div.buscador_container {
        background-size: cover;
        background-position-x: 170vw;
        background-position-y: 210vh;
    }

    div.buscador_container div.contenido {
        width: 90%;
        margin-left: 4%;
    }
}