/*NavBar*/

nav.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 25vh;
  height: 15vh;
  padding: 3%;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
}

nav.navbar a {
  width: 15%;
  margin: 0 0 1% 2%;
}

nav.navbar img {
  width: 15%;
}

nav.navbar img.bandera_dominicana {
  width: 6%;
}

nav.navbar h2 {
  margin-bottom: 0px;
  margin-top: 0px;
  float: left;
  margin-left: 7%;
}

div.navbar_btn {
  width: 30%;
}

div.navbar_btn ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 8%;
}


div.navbar_btn ul li {
  list-style: none;
}

div.navbar_btn ul li a.lupa_nav_btn {
  padding: 10%;
  font-weight: bold;
}

div.navbar_btn ul li button.blog_nav_btn {
  border-style: solid;
  padding: 10%;
  border-radius: 10px;
  font-weight: bold;
}

div.navbar_btn ul li button.blog_nav_btn:active {
  border-style: solid;
  padding: 10%;
  border-radius: 10px;
  font-weight: bold;
  color: #FEDE00;
}

div.navbar_btn ul li a.ingreso_registro {
  color: #d8007e;
  border-color: #d8007e;
  padding: 4% 12%;
  border-radius: 20px;
  white-space: nowrap;
  cursor: pointer;
}

div.navbar_btn ul li a.ingreso_registro .share {
  margin-right: 3%;
}

div.navbar_btn ul li a.redes_icon {
  font-size: 30px;
  color: #ba007c;
}

div.menu_navegacion a.redes_icon_responsive {
  font-size: 30px;
  color: #ffffff;
}

div.menu_navegacion span.redes_moviles {
  display: flex;
  justify-content: space-around;
}

a:link, a:visited, a:active {
  font-family: 'FreeSans';
  text-decoration: none;
  color: #ba007c;
  font-size: 25px;
}

nav.navbar div.desplegable_btn .desplegable_icon {
  color: #ba007c;
  font-size: 25px;
  display: none;
}


/*Responsive*/

@media only screen and (max-width: 992px) {
 
}

@media only screen and (max-width: 768px) {

  nav.navbar img {
    width: 32%;
  }
  div.navbar_btn ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  div.navbar_btn ul li {
    margin-right: 5%;
  }
  nav.navbar div.desplegable_btn .desplegable_icon {
    font-size: 40px;
    display: none;
  }
  a:link, a:visited, a:active {
    font-size: 18px;
  }
  nav.navbar div.navbar_btn ul .li_redes span.iconos_redes {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}

@media only screen and (max-width: 600px) {
  nav.navbar img {
    width: 50%;
  }
  nav.navbar div.navbar_btn {
    display: flex;
  }
  nav.navbar div.desplegable_btn .desplegable_icon {
    font-size: 40px;
    display: flex;
  }
  .menu_navegacion {
    position: fixed;
    padding: 2%;
    top: 0;
    left: 0;
    width: 100%;
    height: 70vh;
    background-color: #ba007C;
  }
  a:link, a:visited, a:active {
    color: #ffffff;
  }
  .menu_navegacion ul {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 90%;
    justify-content: space-evenly;
  }
  .menu_navegacion ul li {
    margin: 2%;
    width: 100%;
    list-style: none;
  }

  nav.second_navbar{
    display: none;
  }
}